<template>
    <div class="wrapper">
        <AdminSideBar></AdminSideBar>
        <section>
            <router-view></router-view>
        </section>
    </div>
</template>

<script>
import AdminSideBar from '../AdminSideBar.vue';
export default {
    components: { AdminSideBar }
}
</script>